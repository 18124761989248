var accordion = {
    init: function () {
        $( '.js-accordion' ).click( function ( e ) {
            e.preventDefault();
            let $this = $( this );
            if ( $this.next().hasClass( 'c-accordion-active' ) ) {
                $this.next().removeClass( 'c-accordion-active' );
                $this.removeClass( 'c-accordion-active' );
                $this.next().slideUp( 350 );
            } 
            else {
                $( '.c-accordion__content' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__title' ).removeClass( 'c-accordion-active' );
                $( '.c-accordion__content' ).slideUp( 350 );
                $this.next().toggleClass( 'c-accordion-active' );
                $this.toggleClass( 'c-accordion-active' );
                $this.next().slideToggle( 350 );


                var screenwidth = $(window).width();	
                if (screenwidth < 1000) {
                    // $this.next().css('border','4px solid red');
                    // $('.accordeon').css('border','4px solid red');
                    // var offset = $this.next().offset();
                    var offset = $this.next().offset();
                    if(offset) {
                        // $('html,body').animate({ scrollTop: $this.next().offset().top - 120}, 'slow');
                        $('html,body').animate({ scrollTop: $('.accordeon').offset().top - 60}, 250);
                        // console.log(offset)
                    }
                }
                else {            }


            }


        } );
    }
}