var setPreviewHeight = {
	init: function () {

		var screenwidth = $(window).width();
		var screenheight = $(window).outerHeight();
		var headerheight = $('header').outerHeight();
		var footerheight = $('footer').outerHeight();

		if (screenwidth >= 1000) {
			$('main').css('min-height', screenheight - headerheight - footerheight);
			$('main > .container').css('min-height', screenheight - headerheight - footerheight);
			$('main > .container > .row').css('min-height', screenheight - headerheight - footerheight);
		}
		else {
			// $('header').removeClass('shrink');
		}

		$('main').css('opacity', '1');
	}
}

var detachPrintSamples = {
	init: function () {

		var screenwidth = $(window).width();
		var printSamplesLg = $('.print--samples-on-lg').html();
		var printSamplesSm = $('.print--samples-on-sm').html();

		if (screenwidth >= 1000) {
			$('.print--samples-on-sm').empty();
			$('.print--samples-on-lg').append(printSamplesSm);
		}
		else {
			$('.print--samples-on-lg').empty();
			$('.print--samples-on-sm').append(printSamplesLg);
		}
	}
}

window.onload = function () {
	accordion.init();
	collapse.init();
	setPreviewHeight.init();
	detachPrintSamples.init();
}
$(window).resize(function () {
	setPreviewHeight.init();
	detachPrintSamples.init();
});


// Show/Hide Popup Cart
var previewProceedHeight = $('.preview--proceed').outerHeight();
var previewImageMarginTop = $('.preview--image').css("marginTop");
$('.preview--popup').css('margin-bottom', - previewProceedHeight - 16);


$(document).ready(function () {
	$(".preview--proceed button").click(function () {
		$(".preview--popup").slideDown(350);
		$('.preview--image').css('margin-top', '0');
	});
	$(".preview--popup .btn").click(function () {
		$(".preview--popup").slideUp(250);
	});
});
$(document).mouseup(function (e) {
	var container = $(".preview--popup");
	if (!container.is(e.target) && container.has(e.target).length === 0) {
		container.slideUp(250);
		$('.preview--image').css('margin-top', previewImageMarginTop);
	}
});
//


$(document).ready(function () {
	// tooltips
	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})
	//
	// fancybox	
	$('[data-fancybox]').fancybox({
		toolbar: false,
		buttons: [
			"close"
		],
		afterShow: function (fb, item) {
			var content = fb.$refs.stage.find('.fancybox-content');
			content.append(fb.$refs.caption); // move caption
		}
	})
	//
	// swiper slider for colours & shapes
	var swipercolours = new Swiper(".swiper-colours", {
		// slidesPerView: 9,
		slidesPerView: "auto",
		slidesPerGroup: 5,
		spaceBetween: 5,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
	var swipershapes = new Swiper(".swiper-shapes", {
		slidesPerView: 7,
		slidesPerGroup: 5,
		spaceBetween: 5,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
	//
	// plus-minus increment/decrement
	function incrementValue(e) {
		e.preventDefault();
		var fieldName = $(e.target).data('field');
		var parent = $(e.target).closest('div');
		var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

		if (!isNaN(currentVal)) {
			parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
		} else {
			parent.find('input[name=' + fieldName + ']').val(0);
		}
	}
	function decrementValue(e) {
		e.preventDefault();
		var fieldName = $(e.target).data('field');
		var parent = $(e.target).closest('div');
		var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

		if (!isNaN(currentVal) && currentVal > 1) {
			parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
		} else {
			parent.find('input[name=' + fieldName + ']').val(1);
		}
	}
	$('.input-group').on('click', '.button-plus', function (e) {
		incrementValue(e);
	});
	$('.input-group').on('click', '.button-minus', function (e) {
		decrementValue(e);
	});
	//
	// add an active class on clicked shape, size & colour 
	$(".print--shape span").click(function () {
		$('.print--shape span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	$(".print--size span").click(function () {
		$('.print--size span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	$(".print--colour.colour--sticker span").click(function () {
		$('.print--colour.colour--sticker span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	$(".print--colour.colour--text span").click(function () {
		$('.print--colour.colour--text span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	$(".print--colour.colour--icon span").click(function () {
		$('.print--colour.colour--icon span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	$(".print--icons span").click(function () {
		$('.print--icons span.active').not(this).removeClass('active');
		$(this).addClass('active');
	});
	//
	// custom file upload
	$(document).on('change', '.file-input', function () {
		var filesCount = $(this)[0].files.length;
		var textbox = $(this).prev();
		if (filesCount === 1) {
			var fileName = $(this).val().split('\\').pop();
			textbox.text(fileName);
		} else {
			textbox.text(filesCount + ' bestanden klaar om op te laden');
		}
	});
	//

});